"use client";

import { useLayoutEffect } from "react";
import { setInitialRenderDone } from "src/lib/ClientHooks";
const InitialLayoutDetector = () => {
  useLayoutEffect(() => {
    setInitialRenderDone();
  });
  return null;
};
export default InitialLayoutDetector;