"use client";

import clsx from "clsx";
import Button, { type ButtonProps } from "src/components/common/Button/Button";
import Tooltip from "src/components/common/Tooltip/Tooltip";
import styles from "./IconButton.module.scss";
export interface IconButton extends ButtonProps {}
const IconButton = (props: IconButton) => {
  const {
    className,
    variant,
    label,
    ...otherProps
  } = props;
  return <Tooltip data-sentry-element="Tooltip" data-sentry-component="IconButton" data-sentry-source-file="IconButton.tsx">
      <Tooltip.Trigger asChild={true} data-sentry-element="unknown" data-sentry-source-file="IconButton.tsx">
        <Button label={label} className={clsx(styles.iconButton, styles[`variant-${variant ?? "normal"}`], className)} variant={variant} {...otherProps} data-sentry-element="Button" data-sentry-source-file="IconButton.tsx" />
      </Tooltip.Trigger>
      <Tooltip.Content data-sentry-element="unknown" data-sentry-source-file="IconButton.tsx">{label}</Tooltip.Content>
    </Tooltip>;
};
export default IconButton;